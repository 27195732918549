import BaseBean from "@/utils/BaseBean";

export interface ICpmCardDataObj {
    utilInst:CpmCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class CpmCardUtil extends BaseBean{
    public dataObj:ICpmCardDataObj;

    constructor(proxy:any,dataObj:ICpmCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建品牌下拉数据
        this.dataObj.otherParams.brandData=await this.utils.OrderProviderApi.getAllBrand({});
        this.dataObj.otherParams.cxData=await this.utils.OrderProviderApi.getAllCx({});
    }

    //根据品牌查询品牌下的所有车型数据
    public async getCxByBrand(options:any):Promise<void>{
        //构建品牌下拉数据
        this.dataObj.otherParams.cxData=await this.utils.OrderProviderApi.getAllCx({params:options});
    }
}