import BaseBean from "@/utils/BaseBean";

export interface ICpmListDataObj {
    utilInst:CpmListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class CpmListUtil extends BaseBean{
    public dataObj:ICpmListDataObj

    constructor(proxy:any,dataObj:ICpmListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建品牌下拉数据
        this.dataObj.otherParams.brandData=await this.utils.OrderProviderApi.getAllBrand({});
        this.dataObj.otherParams.cxData=await this.utils.OrderProviderApi.getAllCx({});
    }
    //根据品牌查询品牌下的所有车型数据
    public async getCxByBrand(options:any):Promise<void>{
        //构建品牌下拉数据
        this.dataObj.otherParams.cxData=await this.utils.OrderProviderApi.getAllCx({params:options});
    }
}