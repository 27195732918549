export default {
    zh:{
        cpm:{
            queryParam:{
                brandId:'品牌',
                brandId_placeholder:'请选择品牌',
                cxId:'车型',
                cxId_placeholder:'请选择车型',
                cpm:'成品码',
                cpm_placeholder:'请输入成品码',
                color:'颜色',
                color_placeholder:'请输入颜色',
                flag:'是否下架',
                flag_placeholder:'请选择是否下架',
            },
            columns:{
                F_BRAND_ID:'品牌',
                F_CX_ID:'车型',
                F_CPM:'成品码',
                F_COLOR:'颜色',
                F_FLAG:'是否下架',
            },
            form:{
                brandId:'品牌',
                brandId_placeholder:'请选择品牌',
                brandId_rule: '请选择品牌',
                cxId:'车型',
                cxId_placeholder:'请选择车型',
                cxId_rule: '请选择车型',
                cpm:'成品码',
                cpm_placeholder:'请输入成品码',
                cpm_rule: '请输入成品码',
                color:'颜色',
                color_placeholder:'请输入颜色',
                color_rule: '请输入颜色',
                desc:'描述',
                desc_placeholder:'请输入描述',
            },
        }
    },
    en:{
        cpm:{
            queryParam:{
                brandId:'品牌',
                brandId_placeholder:'请选择品牌',
                cxId:'车型',
                cxId_placeholder:'请选择车型',
                cpm:'成品码',
                cpm_placeholder:'请输入成品码',
                color:'颜色',
                color_placeholder:'请输入颜色',
                flag:'是否下架',
                flag_placeholder:'请选择是否下架',
            },
            columns:{
                F_BRAND_ID:'品牌',
                F_CX_ID:'车型',
                F_CPM:'成品码',
                F_COLOR:'颜色',
                F_FLAG:'是否下架',
            },
            form:{
                brandId:'品牌',
                brandId_placeholder:'请选择品牌',
                brandId_rule: '请选择品牌',
                cxId:'车型',
                cxId_placeholder:'请选择车型',
                cxId_rule: '请选择车型',
                cpm:'成品码',
                cpm_placeholder:'请输入成品码',
                cpm_rule: '请输入成品码',
                color:'颜色',
                color_placeholder:'请输入颜色',
                color_rule: '请输入颜色',
                desc:'描述',
                desc_placeholder:'请输入描述',
            },
        }
    }
}