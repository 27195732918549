import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import CpmCardUtil,{ICpmCardDataObj} from './cpmCardUtil';
export default defineComponent({
    name:'CpmCard',
    title:'成品码',
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let dataObj:ICpmCardDataObj=reactive<ICpmCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/cpm')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                brandId: [utils.UtilPub.commonValidRule(proxy.$t('cpm.form.brandId_rule'))],
                cxId: [utils.UtilPub.commonValidRule(proxy.$t('cpm.form.cxId_rule'))],
                cpm: [utils.UtilPub.commonValidRule(proxy.$t('cpm.form.cpm_rule'))],
                color: [utils.UtilPub.commonValidRule('请输入英文颜色描述')],
                zhColor: [utils.UtilPub.commonValidRule('请输入中文颜色描述')],
            },
            otherParams:{
                disableFlag:false,//编辑的时候成品码和颜色不能更改
                brandData:[],
                cxData:[]
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new CpmCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'brandId'==params.comboId){
                    return dataObj.otherParams.brandData;
                }
                if(params && 'cxId'==params.comboId){
                    return dataObj.otherParams.cxData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async(newValue:string,selectId:string)=>{
            if('brandId'==selectId){
                dataObj.form.cxId='';
                dataObj.otherParams.cxData=[];
                if(newValue)await dataObj.utilInst.getCxByBrand({brandId:newValue});
            }
            if('cxId'==selectId){}
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            addOrLoad=='/load'?dataObj.otherParams.disableFlag=true:dataObj.otherParams.disableFlag=false;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {
                    // autoUpload:false,
                    belongMaxId:dataObj.form.id,
                    fileSize:80,
                    limitNum:1,
                    listType:'picture-card',
                    accept:'.jpg,.jpeg,.png',
                    showFileList:true,
                    uploadType:params.uploadType,
                    type:params.type,
                    title:params.title
                }
            }
        })
        const uploadHandler=()=>{
            console.log(dataObj.refMap.get('cxGgh').uploadComp)
            dataObj.refMap.get('cxGgh').uploadComp.submit()
        }
        //上传之前
        const beforeUpload=async (file:any,img:any,inst:any)=>{
            img=await img;
            if(img.width!=img.height){
                proxy.$message({type:'warning',message:'图片宽高须满足1比1'});
                return false;
            }
            return true;
        }
        //上传完成之后(可考虑干掉)
        const afterResult=async (res:any)=>{
            await proxy.engine.engineUtil.doAddOrLoad(dataObj.form.id,'card');
        }
        const stopHandler=async ()=>{
            utils.Tools.configBox({
                message:'确定下架吗?',
                sureFn:async ()=> {
                    let res = await utils.Api.postRequest({ url: "/cpm/custom", params: {id:dataObj.form.id,operateType:'stop'}});
                    if(res.result){
                        utils.Tools.success({ message: res.msg });
                        await proxy.engine.engineUtil.doAddOrLoad(dataObj.form.id,'card');
                        proxy.engine.engineParams.ownerComp.queryHandler(false);
                    }
                }
            });
        }
        const startHandler=async ()=>{
            utils.Tools.configBox({
                message:'确定上架吗?',
                sureFn:async ()=> {
                    let res = await utils.Api.postRequest({ url: "/cpm/custom", params: {id:dataObj.form.id,operateType:'start'}});
                    if(res.result){
                        utils.Tools.success({ message: res.msg });
                        await proxy.engine.engineUtil.doAddOrLoad(dataObj.form.id,'card');
                        proxy.engine.engineParams.ownerComp.queryHandler(false);
                    }
                }
            });
        }

        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,beforeOpen,beforeSaveHandler,buildUploadInitParams,uploadHandler,
            afterResult,beforeUpload,stopHandler,startHandler
        }
    }
});